<template>
  <cz-dialog v-bind="$attrs" v-on="$listeners" :loading="loading">
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form @submit.prevent="onSubmit">
        <cz-form-field
          :label="$t('admin.workflowManagement.workflowDialog.workflowName')"
          required
        >
          <validation-provider
            rules="required"
            :name="$t('admin.workflowManagement.workflowDialog.workflowName')"
            v-slot="{ errors }"
          >
            <cz-input v-model="form.name" :error-messages="errors" />
          </validation-provider>
        </cz-form-field>

        <cz-form-field
          :label="$t('admin.workflowManagement.workflowDialog.workflowType')"
          required
        >
          <validation-provider
            rules="required"
            :name="$t('admin.workflowManagement.workflowDialog.workflowType')"
            v-slot="{ errors }"
          >
            <cz-autocomplete
              v-model="form.type"
              :items="workflowTypesItems"
              :error-messages="errors"
            />
          </validation-provider>
        </cz-form-field>

        <cz-button
          type="submit"
          color="primary"
          :disabled="invalid"
          :loading="loading"
          :title="
            $t('admin.workflowManagement.workflowDialog.createWorkflowButton')
          "
        />
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzFormField,
  CzInput,
  CzAutocomplete,
  CzButton
} from '@/components';
import { workflowTypes } from '../constants';
export default {
  name: 'ManageWorkflowDialog',
  components: {
    CzDialog,
    CzFormField,
    CzInput,
    CzAutocomplete,
    CzButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    workflowTypesItems() {
      return this.workflowTypes(this);
    }
  },
  data() {
    return {
      form: {
        name: '',
        type: ''
      },
      workflowTypes
    };
  },
  methods: {
    onSubmit() {
      this.$emit('on-submit', this.form);
    }
  }
};
</script>

<style lang="scss" scoped></style>
