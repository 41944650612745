import { render, staticRenderFns } from "./ManageWorkflowDialog.vue?vue&type=template&id=5020eb6f&scoped=true"
import script from "./ManageWorkflowDialog.vue?vue&type=script&lang=js"
export * from "./ManageWorkflowDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5020eb6f",
  null
  
)

export default component.exports